import React from 'react';
import AboutUs from '../contents/AboutUs';
import Hours from '../contents/Hours';
import Access from '../contents/Access';
import Belongings from '../contents/Belongings';
import Events from '../contents/Events';
import ScrollToTopButton from '../common/ScrollToTopButton';
import HeadLine from '../contents/HeadLine';
import Trial from '../contents/Trial';
import Plans from '../contents/Plans';

function Home() {
  return (
    <div className="relative">
      {/* トップ画像セクション */}
      <section id="top-image" className="relative overflow-hidden">
        {/* 背景用の固定画像 */}
        <img
          src="/images/seiryo_top.jpeg"
          alt="Top"
          className="absolute top-0 left-0 w-full h-full object-cover"
          style={{
            position: 'fixed', // 背景として固定
            zIndex: '-1', // 背面に配置
          }}
        />
      </section>
      <HeadLine />
      {/* コンテンツ */}
      <div id="heading" className="p-4 mt-screen relative z-10 pb-16">
        {/* 背景用の透過レイヤー */}
        <div className="absolute top-0 left-0 w-full h-full bg-white opacity-50 -z-10"></div>
        <Trial />
        <Plans />
        <Events />
        <AboutUs />
        <Hours />
        <Belongings />
        <Access />
      </div>

      {/* ▲Topボタン */}
      <ScrollToTopButton />
    </div>
  );
}

export default Home;
