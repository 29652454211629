import React from 'react';

const demoSites = [
  {
    id: 1,
    image: '/images/startup.jpeg',
    name: 'スタートアッププラン',
    description: 'お仕事で多忙な方へ。まずは月2回、気軽に始めたい方向け。',
    monthlyPrice: '8,000円',
    admissionFee: '20,000円',
    features: ['月2回、お好きな日時をご指定可能'],
    notices: ['退会された場合でも、入会金は返金されません'],
  },
  {
    id: 2,
    image: '/images/weekly.jpeg',
    name: '毎週しっかりプラン',
    description: '週に1回、定期的にしっかり学びたい方向け。',
    monthlyPrice: '15,000円',
    admissionFee: '20,000円',
    features: ['月5回、お好きな日時をご指定可能', 'お茶会など各種イベントに特別価格で参加可能'],
    notices: ['退会された場合でも、入会金は返金されません'],
  },
  {
    id: 3,
    image: '/images/free.jpeg',
    name: 'フリープラン',
    description: '気が向いた時にだけお稽古したい方へ。お稽古に参加した分だけのお支払い。',
    oneTimePrice: '5,000円',
    admissionFee: '20,000円',
    features: ['月額料金なし', 'ご参加時に毎回お支払い'],
    notices: ['退会された場合でも、入会金は返金されません'],
  },
  {
    id: 4,
    image: '/images/kiwame.jpeg',
    name: '"極（きわめ）"プラン',
    description: '週に2回、茶道を本格的に極めたいと思っている方向け。',
    monthlyPrice: '25,000円',
    admissionFee: '10,000円',
    features: ['月10回、お好きな日時をご指定可能', 'お茶会など各種イベントに特別価格で参加可能'],
    notices: ['退会された場合でも、入会金は返金されません'],
  },
];

const Plans = () => {
  return (
    <section id="plans" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="glowing-text font-semibold text-4xl md:text-6xl mb-6 text-center">料金プラン</h2>
      {demoSites.map((site, index) => (
        <div
          key={site.id}
          className={`flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row-reverse' : ''} items-center bg-white rounded-lg shadow-md p-6 mb-8`}
        >
          {/* 画像部分 */}
          <div className="w-full md:w-1/2">
            <img
              src={site.image}
              alt={site.name}
              className="w-full object-cover rounded-md shadow-sm"
            />
          </div>

          {/* 説明部分 */}
          <div className="w-full md:w-1/2 text-center p-4">
            <h3 className="inline-block border-b border-gray-600 text-2xl md:text-4xl font-semibold mb-8">
              {site.name}
            </h3>
            <p className="text-gray-700 mb-4">{site.description}</p>

            {/* 料金表示（スマホでも横並び） */}
            <div className="flex flex-row gap-2 mt-4">
              <div className="w-1/2 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
                <p className="text-sm font-bold text-gray-900 text-center">
                  {site.monthlyPrice ? "月額" : "1回"}
                </p>
                <p className="text-sm font-semibold text-gray-800 text-center">
                  {site.monthlyPrice || site.oneTimePrice}<small> 税込</small>
                </p>
              </div>
              <div className="w-1/2 bg-gray-100 border border-gray-400 p-3 rounded-lg shadow-md">
                <p className="text-sm font-bold text-gray-900 text-center">入会金</p>
                <p className="text-sm font-semibold text-gray-800 text-center">
                  {site.admissionFee}<small> 税込</small>
                </p>
              </div>
            </div>

            {/* 特典リスト */}
            <ul className="text-gray-700 mb-4 space-y-2 mt-4 text-left md:px-10">
              {site.features.map((feature, index) => (
                <li key={index}>✅ {feature}</li>
              ))}
            </ul>

            {/* 注意事項 */}
            <ul className="text-gray-500 text-xs mb-4 space-y-1">
              {site.notices.map((notice, index) => (
                <li key={index}>※ {notice}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Plans;
