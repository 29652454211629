import React from 'react';

const belongingsItems = [
  { name: "点前袱紗（てまえぶくさ）", description: "茶道具を拭くときに使ったりします", image: "temae_fukusa.jpeg" },
  { name: "小袱紗（こぶくさ）", description: "茶道具の下に敷いて使ったりします", image: "kobukusa.jpeg" },
  { name: "懐紙（かいし）", description: "お皿やティッシュの役目をします", image: "kaishi.jpeg" },
  { name: "楊枝（ようじ）", description: "茶菓子を食べるときだけ使います", image: "yoji.jpeg" },
  { name: "扇子（せんす）", description: "お辞儀するときに使います", image: "sensu.jpeg" },
  { name: "替足袋（かえたび）", description: "白いソックスでいいです", image: "tabi.jpg" },
];

const Belongings = () => {
  return (
    <section id="belongings" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="glowing-text font-semibold text-4xl md:text-6xl mb-10 text-center">稽古の持物</h2>
      <div className='bg-gray-600 shadow-md rounded-md overflow-hidden p-2 md:p-6'>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-6">
          {belongingsItems.map((item, index) => (
            <div key={index} className="bg-white shadow-md rounded-md overflow-hidden text-center p-1 md:p-4">
              <img
                src={`/images/${item.image}`}
                alt={item.name}
                className="w-full h-48 md:h-48 object-cover rounded-md"
              />
              <div className="p-4">
                <p className="text-sm md:text-lg font-semibold inline-block border-b">{item.name}</p>
                <p className="text-xs md:text-sm text-gray-600 mt-2">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <p className="text-sm p-4 text-center text-white">
          ・初回は「白いソックス」だけで結構です。他は教室で用意あります。
        </p>
      </div>
    </section>
  );
};

export default Belongings;
