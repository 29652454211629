import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaInstagram } from 'react-icons/fa';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white opacity-95 p-2 md:p-4 fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center max-w-screen-lg">
        <div className="flex items-end px-2">
          <Link smooth to="/" className="tracking-widest text-black text-2xl md:text-3xl font-semibold no-underline hover:no-underline">
            静亮庵
          </Link>
          <span className="ml-4 text-sm md:text-lg align-baseline">江戸千家渭白流</span>
        </div>

        {/* モバイル表示のアイコン + ハンバーガーメニュー */}
        <div className="flex items-center md:hidden">
          <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="text-3xl text-pink-500 hover:text-pink-400 mr-4 mt-2">
            <FaInstagram />
          </a>
          <button className="text-4xl focus:outline-none" onClick={toggleMenu}>
            {isOpen ? '✕' : '☰'}
          </button>
        </div>

        {/* PC用のナビゲーション */}
        <nav className="hidden md:flex items-center">
          <Link smooth to="/#plans" className="mr-4">料金</Link>
          <Link smooth to="/#events" className="mr-4">茶会</Link>
          <Link smooth to="/#about" className="mr-4">紹介</Link>
          <Link smooth to="/#hours" className="mr-4">稽古日</Link>
          <Link smooth to="/#belongings" className="mr-4">持ち物</Link>
          <Link smooth to="/#access" className="mr-4">アクセス</Link>
          {/* PC版のInstagramアイコン */}
          <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="text-2xl text-pink-500 hover:text-pink-400">
            <FaInstagram />
          </a>
        </nav>
      </div>

      {/* モバイル用メニュー */}
      {isOpen && (
        <div className="fixed inset-0 bg-lime-700 text-white glowing-text flex flex-col justify-center items-center text-4xl">
          <button
            className="absolute top-4 right-4 text-4xl focus:outline-none"
            onClick={toggleMenu}
          >
            ✕
          </button>
          <Link smooth to="/#plans" className="my-4 no-underline hover:underline" onClick={toggleMenu}>料金プラン</Link>
          <Link smooth to="/#events" className="my-4 no-underline hover:underline" onClick={toggleMenu}>茶会・イベント</Link>
          <Link smooth to="/#about" className="my-4 no-underline hover:underline" onClick={toggleMenu}>道場の紹介</Link>
          <Link smooth to="/#hours" className="my-4 no-underline hover:underline" onClick={toggleMenu}>稽古日・時間</Link>
          <Link smooth to="/#belongings" className="my-4 no-underline hover:underline" onClick={toggleMenu}>稽古の持物</Link>
          <Link smooth to="/#access" className="my-4 no-underline hover:underline" onClick={toggleMenu}>アクセス</Link>
          {/* モバイルメニューのInstagramアイコン */}
          <a href="https://www.instagram.com/seiryo_kishida/" target="_blank" rel="noopener noreferrer" className="mt-4 text-7xl text-pink-400 hover:text-pink-500">
            <FaInstagram />
          </a>
        </div>
      )}
    </header>
  );
}

export default Header;
