import React from 'react';

// イベントデータ（画像URLを含む）
const events = [
  {
    title: "渭白会（いはくかい）茶会",
    location: "護国寺境内茶室（文京区）",
    participants: "全員",
    schedule: "毎年1回（不定期）",
    time: "8:00 〜 15:00",
    normalPrice: "25,000円",
    specialPrice: "15,000円",
    notes: "諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event1.jpeg",
  },
  {
    title: "初釜（はつがま）",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年1月の第2日曜日",
    time: "10:00 〜 14:00",
    normalPrice: "40,000円",
    specialPrice: "30,000円",
    notes: "諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event2.JPG",
  },
  {
    title: "総会（そうかい）",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年7月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    normalPrice: "15,000円",
    specialPrice: "10,000円",
    notes: "諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event3.jpeg",
  },
  {
    title: "納会（のうかい）",
    location: "家元道場（稽古場）",
    participants: "全員",
    schedule: "毎年12月の第2 or 3日曜日",
    time: "10:00 〜 14:00",
    normalPrice: "15,000円",
    specialPrice: "10,000円",
    notes: "諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event4.jpeg",
  },
  {
    title: "研究会（けんきゅうかい）",
    location: "家元道場（稽古場）",
    participants: "師範以上",
    schedule: "毎年4回程度（不定期）",
    time: "10:00 〜 14:00",
    normalPrice: "15,000円",
    specialPrice: "10,000円",
    notes: "諸経費（水屋費など）を含みます。",
    imageUrl: "/images/event5.jpeg",
  },
];

// イベントカードのレンダリング
const renderEvents = (events) => {
  return events.map((event, index) => {
    const isOdd = index % 2 === 0; // 偶数か奇数か判定

    return (
      <div
        key={index}
        className={`flex flex-col md:flex-row ${
          isOdd ? "md:flex-row-reverse" : ""
        } items-center bg-white shadow-md rounded-md p-6 mb-8`}
      >
        {/* 画像部分 */}
        <div className="w-full md:w-1/2">
          <img
            src={event.imageUrl}
            alt={event.title}
            className="w-full object-cover rounded-md shadow-sm"
          />
        </div>

        {/* 説明部分 */}
        <div className="w-full md:w-1/2 text-center p-4">
          <h3 className="inline-block border-b border-gray-600 text-xl md:text-3xl font-semibold mt-4 mb-8">
            {event.title}
          </h3>
          <ul className="list-disc space-y-2 text-gray-700 text-left px-6 md:px-10">
            <li>
              <strong>場所：</strong> {event.location}
            </li>
            <li>
              <strong>参加者：</strong> {event.participants}
            </li>
            <li>
              <strong>日程：</strong> {event.schedule}
            </li>
            <li>
              <strong>時間：</strong> {event.time}
            </li>
          </ul>

          {/* 料金表示（スマホでも横並び） */}
          <div className="flex flex-row gap-2 mt-4">
            <div className="w-1/2 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
              <p className="text-sm font-bold text-gray-900 text-center">通常価格</p>
              <p className="text-sm font-semibold text-gray-800 text-center">
                {event.normalPrice}<small> 税込</small>
              </p>
            </div>
            {event.specialPrice && (
              <div className="w-1/2 bg-green-100 border border-green-400 p-3 rounded-lg shadow-md">
                <p className="text-sm font-bold text-green-600 text-center">特別価格</p>
                <p className="text-sm font-semibold text-green-500 text-center">
                  {event.specialPrice}<small> 税込</small>
                </p>
              </div>
            )}
          </div>

          {/* 注意事項 */}
          <p className="text-gray-500 text-sm mt-4">※ {event.notes}</p>
        </div>
      </div>
    );
  });
};

// メインのコンポーネント
function Events() {
  return (
    <section id="events" className="container mx-auto max-w-screen-lg pt-36 pb-12">
      <h2 className="glowing-text font-semibold text-4xl md:text-6xl mb-6 text-center">
        茶会・イベント
      </h2>
      <div>{renderEvents(events)}</div>
    </section>
  );
}

export default Events;
